import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text"> Premium Solutions for Your Online Success. </h1>
      <p>Hi, I'm Franklin Arisa, your go-to digital architect, trusted by industry giants to craft cutting-edge web solutions with precision and finesse. With a track record of success and a passion for innovation, 
	  I'm here to tackle your next project and elevate your online presence to new heights. 
      </p>

      <div className="gpt3__header-content__input">
        <input type="email" placeholder="Schedule a call with me!" />
          <a href="https://calendly.com/frankarisah"><button type="button">Book Now</button></a>
      </div>
	  <div className="gpt3__header-content__people">
        <img src={people} />
        <p>3 out of 5 Fortune Five Companies Trust Frank's Services. </p>
      </div>
    </div>

    <div className="gpt3__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
