import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'E-commerce web design',
    text: 'I will use the latest technologies to ensure a secure and reliable e-commerce web design solution that meets the needs of the business. My focus is always on creating a user-friendly and engaging website experience that will attract more customers.',
  },
  {
    title: 'UI/UX Design',
    text: ' I provide superior UI/UX design to help businesses and corporations create a unique digital experience. My designs are crafted with attention to detail, creating a powerful user journey. My goal is to make your ideas come to life and help maximize your customer satisfaction.',
  },
  {
    title: 'Decentralized Apps',
    text: 'I assist ambitious individuals in creating accessible Decentralized Applications (DApps), as the utilization of DApps developed on the blockchain is becoming increasingly widespread. I strive to ensure that the development process is both efficient and successful.',
  },
  {
    title: 'Security Solutions Consultant',
    text: " As a seasoned consultant, I provide expert advisory and implementation services for Security Information and Event Management (SIEM), Privileged Access Management (PAM), and Unified Endpoint Management (UEM) solutions. With a focus on safeguarding your digital assets, I bring efficiency and security to your organization's IT infrastructure.",
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="services">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Let's build something that will create an exceptional digital experience. Let's work together to make it Happen.</h1>
      <p>Request Early Access to Get Started</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
