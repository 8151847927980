import React from 'react';
import gpt3Logo from '../../logoo.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding" >
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">I Embrace Simplicity in My Engineering</h1>
    </div>

    <div className="gpt3__footer-btn">
      <a href="mailto:technical@arisafrank.co.ke"><p>Request Quote</p></a>
    </div>

    <div className="gpt3__footer-links" id="contact">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Socials</h4>
        <a href="https://linkedin.com/in/frank-arisa-542360212"><p>LinkedIn</p></a>
        <a href="https://twitter.com/Franklin_Arisa"><p>Twitter</p></a>
		<a href="https://discord.gg/J5ZSCm4X"><p>Discord</p></a>
        
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>Use AI Chatbot</p>
        <p>+254741575615 / +254711405740 </p>
        <p>technical@arisafrank.co.ke</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2022 - 2024 Arisa. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
