import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p>Get in touch</p>
      <h3>Whether you have a question or just want to say hi, I’ll try my best to get back to you! </h3>
    </div>
    <div className="gpt3__cta-btn">
    <a href="mailto:technical@arisafrank.co.ke">  <button type="button">Say Hello</button> </a>
    </div>
  </div>
);

export default CTA;
