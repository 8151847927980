import React from 'react';
import possibilityImage from '../../assets/Untitled design (44).png';
import cryptoImage from '../../assets/possibilityyy.png';
import mullerImage from '../../assets/MullerFreightServices.png';
import leadingedgeImage from '../../assets/leadingedgeinfosystems.png';
import growthmarketingImage from '../../assets/growthmarketing.png';
import mobileappImage from '../../assets/mobileapp.png';

const Possibility = () => (
  <div className="container">
    <div className="gpt3__possibility section__padding" id="portfolio">
      <div className="row">
        <div className="col-md-6">
          <div className="gpt3__possibility-image" >
            <a href="https://mullerfreight.com">
              <img src={mullerImage} alt="possibility" />
            </a>
          </div>
        </div>
        <div className="col-md-6">
          <div className="gpt3__possibility-image">
		   <a href="https://leadingedgeinfosystems.com">
              <img src={leadingedgeImage} alt="possibility" />       
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="gpt3__possibility-image">
             <a href="https://whispers.arisafrank.co.ke">
              <img src={possibilityImage} alt="possibility" />
            </a>
          </div>
        </div>
		<div className="col-md-6">
          <div className="gpt3__possibility-image">
              <img src={growthmarketingImage} alt="possibility" />       
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="gpt3__possibility-image">
            <a href="https://krypt.arisafrank.co.ke">
              <img src={cryptoImage} alt="possibility" />
            </a>
          </div>
        </div> 
		<div className="col-md-6">
          <div className="gpt3__possibility-image">
              <img src={mobileappImage} alt="possibility" />       
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Possibility;
