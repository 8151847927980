import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">A lot is happening, <br /> I am blogging about it.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
	  <a href="https://arisafrank.co.ke/wp/crapi-walkthrough-using-ai/">
        <Article imgUrl={blog01} date="Jan 05, 2024" text="crAPI walkthrough using AI" />
		</a>
      </div>
      <div className="gpt3__blog-container_groupB">
	  <a href="https://arisafrank.co.ke/wp/using-ai-to-find-api-bugs/">
        <Article imgUrl={blog02} date="Jan 14, 2024" text="Using AI to find API bugs" />
		</a>
		<a href="https://arisafrank.co.ke/wp/vampi-walkthrough/">
        <Article imgUrl={blog03} date="Feb 28, 2024" text="vAPI walkthrough" />
		</a>
		<a href="https://arisafrank.co.ke/wp/test-for-ssrf-in-an-api/">
        <Article imgUrl={blog04} date="March 16, 2024" text="Testing for SSRF in an API" />
		</a>
		<a href="https://arisafrank.co.ke/wp/why-open-source-matters-in-data-engineering/">
        <Article imgUrl={blog05} date="March 25, 2024" text="Why open-source matters in data engineering" />
		</a>
      </div>
    </div>
  </div>
);

export default Blog;
