import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="whoisarisa">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Who is Arisa" text="Arisa is a tech enthusiast, website developer, and cybersecurity specialist on a mission to craft extraordinary digital experiences while fortifying digital security. I turn even the most ambitious projects into reality - on-time and within budget. Let my expertise and security knowledge take your ideas to the next level. " />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Lets make something special</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Me" text="Securely crafting innovative, user-friendly experiences is my passion as a website developer, and I strive to make each experience as engaging and enjoyable as possible for the user." />
      <Feature title="Myself" text="I have developed a keen eye for balancing time and budget constraints while still delivering high quality user experiences. I take great pride in ensuring the utmost security for all of my projects. " />
            <Feature title="& I" text="I'm continually growing my knowledge in software, IT infrastructure, cloud, and DevOps technologies to ensure the security of current and future IT infrastructures." />
    </div>
  </div>
);

export default WhatGPT3;

